// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ng-scrollbar {
  --scrollbar-thumb-color: var(--cui-border-color, #999);
  --scrollbar-track-color: var(--cui-body-color, #fff);
  --scrollbar-hover-size: calc(var(--scrollbar-size) * 1.5);
}`, "",{"version":3,"sources":["webpack://./src/app/containers/default-layout/layout.component.scss"],"names":[],"mappings":"AACE;EACE,sDAAA;EACA,oDAAA;EACA,yDAAA;AAAJ","sourcesContent":[":host {\n  ng-scrollbar {\n    --scrollbar-thumb-color: var(--cui-border-color, #999);\n    --scrollbar-track-color: var(--cui-body-color, #fff);\n    --scrollbar-hover-size: calc(var(--scrollbar-size) * 1.5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
