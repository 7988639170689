// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .c-size-icon .material-symbols-outlined {
  font-size: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/views/users/create-channel/create-channel.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF","sourcesContent":["::ng-deep .c-size-icon .material-symbols-outlined {\n  font-size: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
