// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-content {
  height: 100%;
  min-height: 1050px;
}
.edit-content .flag {
  width: 3.5rem;
  height: 3.5rem;
}
.edit-content .post-message-input,
.edit-content .comment-input {
  font-size: 16px;
  min-height: 45px;
  line-break: anywhere;
  background-color: #fff;
  border: 1px solid #b1b7c1;
  border-radius: 10px;
  padding: 8px;
}
.edit-content .post-message-input[placeholder]:empty:before,
.edit-content .comment-input[placeholder]:empty:before {
  content: attr(placeholder);
  color: #697495;
}
.edit-content .post-message-input[placeholder]:empty:focus:before,
.edit-content .comment-input[placeholder]:empty:focus:before {
  content: "";
}
.edit-content .post-message-input[placeholder]:empty:focus-visible,
.edit-content .comment-input[placeholder]:empty:focus-visible {
  border: 1px solid #b1b7c1;
}
.edit-content .post-message-input .user-dropdown-menu,
.edit-content .comment-input .user-dropdown-menu {
  max-height: 300px;
}

.single-box {
  max-width: 300px;
  background: #3c4b64;
  margin: 10px;
  color: #fff;
}
.single-box .material-symbols-outlined {
  color: #fff;
  cursor: pointer;
}

::ng-deep .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/edit-community/edit-community.component.scss"],"names":[],"mappings":"AAIA;EACE,YAAA;EACA,kBAAA;AAHF;AAIE;EACE,aAAA;EACA,cAAA;AAFJ;AAKE;;EAEE,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,sBAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;AAHJ;AAMM;;EACE,0BAAA;EACA,cAAA;AAHR;AAMQ;;EACE,WAAA;AAHV;AAMM;;EACE,yBAAA;AAHR;AAOI;;EACE,iBAAA;AAJN;;AASA;EACE,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AANF;AAQE;EACE,WAAA;EACA,eAAA;AANJ;;AAaM;EACE,sCAAA;EAAA,iCAAA;AAVR","sourcesContent":["// .avatar-img {\n//   width: 2.5rem;\n//   height: 2.5rem;\n// }\n.edit-content {\n  height: 100%;\n  min-height: 1050px;\n  .flag {\n    width: 3.5rem;\n    height: 3.5rem;\n  }\n\n  .post-message-input,\n  .comment-input {\n    font-size: 16px;\n    min-height: 45px;\n    line-break: anywhere;\n    background-color: #fff;\n    border: 1px solid #b1b7c1;\n    border-radius: 10px;\n    padding: 8px;\n\n    &[placeholder]:empty {\n      &:before {\n        content: attr(placeholder);\n        color: #697495;\n      }\n      &:focus {\n        &:before {\n          content: \"\";\n        }\n      }\n      &:focus-visible {\n        border: 1px solid #b1b7c1;\n      }\n    }\n\n    .user-dropdown-menu {\n      max-height: 300px;\n    }\n  }\n}\n\n.single-box{\n  max-width: 300px;\n  background: #3c4b64;\n  margin: 10px;\n  color: #fff;\n\n  .material-symbols-outlined{\n    color: #fff;\n    cursor: pointer;\n  }\n}\n\n::ng-deep .multiselect-dropdown {\n  .dropdown-btn {\n    .selected-item-container {\n      .selected-item {\n        max-width: fit-content !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
