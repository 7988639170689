// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .app-pagination c-pagination ul {
  margin-bottom: 0px;
}
::ng-deep .app-pagination c-pagination ul .page-item .page-link {
  min-width: 38px;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/pagination/pagination.component.scss"],"names":[],"mappings":"AAEI;EACE,kBAAA;AADN;AAIQ;EACE,eAAA;AAFV","sourcesContent":["::ng-deep {\n  .app-pagination {\n    c-pagination ul {\n      margin-bottom: 0px;\n\n      .page-item {\n        .page-link {\n          min-width: 38px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
