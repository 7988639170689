// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .app-table .table tbody tr:last-child td, ::ng-deep .app-table .table tfoot tr:last-child td {
  border: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/table/table.component.scss"],"names":[],"mappings":"AAIQ;EACE,sBAAA;AAHV","sourcesContent":["::ng-deep {\n  .app-table .table {\n    tbody, tfoot {\n      tr:last-child {\n        td {\n          border: 0px !important;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
